import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import Banner from '../../assets/images/cloud-private-banner.png';
import BannerWrap from '../../components/BannerWrap';
import SectionWrap from '../../components/SectionWrap';

import 'swiper/css';
import 'swiper/css/navigation';
import * as styles from '../storage/index.module.less';
import Button from '../../components/Button';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import { contactUsModalShow } from '../../components/ContactUsModal';
import { cls } from '../../utils';

export const NICE_LIST = [
  {
    title: '自然散热技术',
    desc: '无风扇全金属导冷设计，无音无尘，稳定可靠，更低能耗。',
  },
  {
    title: '三重安全保护',
    desc: '物理安全，防拆卸。意外掉电保护，内置超级电容防止磁头、磁盘损坏。硬盘紧固防护，防止拆盘无痕窃取数据。数据安全，硬加密。内置国产加密芯片，支持国密算法。技术安全，全自主。自主可控，基础软硬件技术均为国产自主研发。',
  },

  {
    title: '高扩展易部署',
    desc: 'NAS系统能够流畅服务几十甚至几百人的团队，三十分钟内即可完成系统部署搭建、人员配置与管理等工作，适合中小企业的扩张需求。',
  },
  {
    title: '简易备份恢复',
    desc: '员工可轻松备份重要文件至NAS，以防数据丢失，并提供便捷的数据恢复选项。',
  },
  {
    title: '高效协同工作',
    desc: '管理员可以为每个员工分配特定的文件夹管理权限、硬盘空间和使用权限，确保数据有序管理和高效使用；通过创建共享文件夹，员工可以轻松地在不同的用户和设备间共享文件，促进团队协作效率。',
  },
  {
    title: '系统创新移植',
    desc: '创造性的将TrueNAS移植至ARM系统。系统具备低功耗运行、资源占用小、ARM系统自主研发、系统成本较低等特点。',
  },
];

export default function CloudPrivatePage() {
  const [machActive, setMachActive] = useState(1);
  return (
    <Layout>
      <BannerWrap
        img={Banner}
        title="土星云网络存储服务器"
        desc="土星云网络存储服务器是通过网络连接使用，具备资料、文档等电子数据存储和共享功能的装置，因此也称为“网络存储器”。存储服务器以数据为中心，可以创建所需的存储协议，给予对应用户使用权限的存储系统。产品采用了基于军工电子技术的低功耗、导冷硬件和基于实时操作系统的高级存储管理软件，可广泛用于医疗、制造、政府、教育、安防领域。"
      />
      <SectionWrap
        title="土星云网络存储服务器"
        background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.machContainer}>
          <div className={styles.machSwiper}>
            <div className={styles.imgViewCard}>
              <img src={`/saturn-product/dn110m-cs/${machActive}.jpg`} alt="" />
            </div>
            <Swiper
              spaceBetween={20}
              slidesPerView={4}
              height={80}
              loop={false}
              navigation
              modules={[Navigation]}
              style={{ padding: '0 40px' }}>
              {Array.from({ length: 5 }).map((item, i) => {
                const index = i + 1;
                let itemClassName = styles.machItem;
                if (index === machActive) {
                  itemClassName += ' ' + styles.machItemActive;
                }
                return (
                  <SwiperSlide key={index} style={{ width: 180 }}>
                    <div
                      className={itemClassName}
                      onClick={() => {
                        setMachActive(index);
                      }}>
                      <img src={`/saturn-product/dn110m-cs/${index}.jpg`} alt="" />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className={styles.machInfo}>
            <div className={styles.machTitle}>土星云网络存储服务器</div>
            <div className={styles.machDesc}>
              土星云网络存储服务器DN110M-CS是针对中小企业的NAS存储产品，基于国产ARM架构的瑞芯微RK3588平台，基于TrueNAS进行了移植适配和定制开发，其具有4个3.5英寸机械硬盘的存储空间，具有高安全、高可靠、高易用性等特性，能够轻松应对中小企业的非结构化数据存储需求。
            </div>
            <div className={styles.machOperate}>
              <a
                href="/saturn-product/dn110m-cs/doc.pdf"
                download="国科环宇土星云网络存储服务器DN110M-CS产品规格书">
                <Button type="primary" style={{ marginRight: 20 }}>
                  资料下载
                </Button>
              </a>
              <Button
                type="outline"
                onClick={() => {
                  contactUsModalShow();
                }}>
                购买咨询
              </Button>
              <a
                href="https://item.jd.com/10100994890482.html"
                target="_blank"
                className={styles.jdLink}></a>
            </div>
          </div>
        </div>
      </SectionWrap>
      <SectionWrap title="产品特性" background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.niceList}>
          {NICE_LIST.map((item, index) => {
            return (
              <div className={styles.niceItem} key={index}>
                <img className={styles.niceIcon} src={`/images/cloud-private-nice-5.png`} alt="" />
                <div className={styles.niceTitle}>{item.title}</div>
                <div className={styles.niceDesc}>{item.desc}</div>
              </div>
            );
          })}
          {/* <div className={styles.niceItem} style={{ visibility: 'hidden' }}></div> */}
        </div>
      </SectionWrap>
      <SectionWrap title="技术规格">
        <table className={cls(styles.normsTable, styles.type2)}>
          <colgroup>
            <col width={100} />
          </colgroup>
          <thead>
            <tr>
              <th colSpan={3} style={{ textAlign: 'center' }}>
                土星云网络存储服务器DN110M-CS
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={3}>存储</td>
              <td>盘位数</td>
              <td>4盘位</td>
            </tr>
            <tr>
              <td>磁盘类型</td>
              <td>3.5" SATA HDD</td>
            </tr>
            <tr>
              <td>存储容量</td>
              <td>32TB/64TB</td>
            </tr>
            <tr>
              <td>处理器</td>
              <td>CPU</td>
              <td>RK3588(4 Cortex-A76+4 Cortex-A55)</td>
            </tr>
            <tr>
              <td>内存</td>
              <td>内存容量</td>
              <td>16GB</td>
            </tr>
            <tr>
              <td rowSpan={3}>I/O</td>
              <td>千兆以太网</td>
              <td>2 Ports 1000Base-T(RJ45)</td>
            </tr>
            <tr>
              <td>USB 3.0</td>
              <td>1 Port (Type-A)</td>
            </tr>
            <tr>
              <td>HDMI</td>
              <td>1 Port</td>
            </tr>
            <tr>
              <td rowSpan={5}>软件</td>
              <td>存储协议</td>
              <td>SMB/CIFS/S3/NFS/FTP/WebDAV/iSCSI等</td>
            </tr>
            <tr>
              <td>RAID类型</td>
              <td>RAID 0/RAID 1/RAID 5/RAID 6/RAID 10</td>
            </tr>
            <tr>
              <td>数据加密</td>
              <td>国密算法SM2/SM3/SM4</td>
            </tr>
            <tr>
              <td>管理工具</td>
              <td>WEB界面/命令行，S3工具</td>
            </tr>
            <tr>
              <td>系统功能</td>
              <td>
                存储池管理，系统硬盘管理，数据分区管理，文件共享，远程控制台，远程访问，用户、组、凭证管理，云端同步和数据迁移等
              </td>
            </tr>
            <tr>
              <td>功耗</td>
              <td>典型功耗</td>
              <td>＜60W</td>
            </tr>
            <tr>
              <td>散热</td>
              <td>散热方式</td>
              <td>金属导冷自然散热</td>
            </tr>
            <tr>
              <td>电源</td>
              <td>供电模式</td>
              <td>12V DC</td>
            </tr>
            <tr>
              <td rowSpan={4}>环境</td>
              <td>工作温度</td>
              <td>0℃~+50℃</td>
            </tr>
            <tr>
              <td>工作湿度</td>
              <td>40℃@95% 相对湿度，无冷凝</td>
            </tr>
            <tr>
              <td>储存温度</td>
              <td>-40℃~+85℃</td>
            </tr>
            <tr>
              <td>储存湿度</td>
              <td>储存湿度：60℃@95% 相对湿度，无冷凝</td>
            </tr>
            <tr>
              <td>机械</td>
              <td>尺寸</td>
              <td>193 宽 × 170 高 × 182 深（单位：mm）</td>
            </tr>
          </tbody>
        </table>
      </SectionWrap>
    </Layout>
  );
}

export function Head() {
  return <SEO title="私有云" />;
}
